/* eslint-disable no-console */
import { types } from '../../types';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import { FabricServicesAxios, getFabricURL, getFabricEndpoint } from '../../../common/axios/FabricServicesAxios';
import { datadogRum } from '@datadog/browser-rum';
import connectionType from '../../../common/helpers/connectionType';
import { ddRumAction } from '../../../common/helpers/datadog-wrapper';
import { ddActionGetUserContextReceived } from '../../../common/datadog/get-user/gu-context-received';
import { DD_USE_CASES } from '../../../common/datadog/enums';
import { getPriorSession, getUserData } from '../../../unified-modules/account-library';
import { getAccountLogger } from "../../../unified-modules/account-library-logger";

export const getUserContext = () => async dispatch => {
    const requestUid = getRequestUid(FetchType.UserContext);
    const fabricURL = getFabricURL();
    const endpoint = getFabricEndpoint('userContext', 'accountSettings');
    const baseURL = process.env.NODE_CONFIG_ENV === 'local-development' ? `${fabricURL}:4000` : fabricURL;
    const accountLogger = getAccountLogger(true);

    const product = process.env.PRODUCT;

    try {
        let userData = null;
        let priorSession = null;
        try {
            userData = await getUserData();
            priorSession = await getPriorSession();

            if (!userData) {
                accountLogger.log(`[UserContext] Unable to retrieve user data`);
            }

            if (!priorSession) {
                accountLogger.log(`[UserContext] Unable to retrieve prior session data`);
            }

        } catch (err){
            accountLogger.log(`[UserContext] Error getting user details: ${err}`);
            console.log(`[UserContext] Error getting user details:`, err);
        }

        dispatch(fetchStart(requestUid));
        dispatch({ type: types.GET_USER_CONTEXT_REQUEST });

        const response = await FabricServicesAxios().get(endpoint,{
            baseURL,
            params: {
                product
            },
            withCredentials: true
        });

        dispatch({
            type: types.GET_USER_CONTEXT_RECEIVED,
            payload: {
                ...response?.data,
                accountSettings: {
                    ...response?.data?.accountSettings,
                    accountID: userData?.userId,
                    username: userData?.username,
                    firstName: userData?.firstName,
                    lastName: userData?.lastName,
                    countryCode: userData?.country,
                    lastLoginDate: priorSession?.created
                }
            }
        });

        ddRumAction(ddActionGetUserContextReceived(DD_USE_CASES.GAME_LAUNCHER));
        dispatch(fetchEnd(requestUid));
    } catch (error) {
        dispatch({
            type: types.GET_USER_CONTEXT__FAILED,
            payload: { name: error }
        });
        dispatch(fetchError(requestUid));

        datadogRum.addError(
            new Error(error),
            Object.assign(
                {
                    type: 'GAME_WINDOW',
                    connectionType: connectionType()
                },
                error
            ),
        );
    }
};

export default getUserContext;
